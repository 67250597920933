<template>
  <widget-content class="store-widget-wrap" :bg="model">
    <div class="flex justify-center store-title-rich-wrap">
      <rich-text
        v-if="model.titleVisible"
        v-model="model.title"
        :editing="editing"
        :disabled="!editing"
        placement="bottom"
        theme="snow"
        :class="$store.getters.isDesktop && 'xl:text-[40px]'"
        class="text-[20px] w-full title"
      ></rich-text>
    </div>

    <!-- 修改商品布局组件 -->
    <div
      v-if="isShowLayoutBtn"
      class="store-menu__icon"
      @click="infoMenuVisible = !infoMenuVisible"
    >
      <InfoMenuIcon v-if="infoMenuVisible" />
      <i v-else class="el-icon-menu"></i>
    </div>

    <!-- 商品导航组件 -->
    <div v-if="isShowStoreNav" class="nav-container">
      <div class="nav-wrap">
        <div
          v-for="s in model.stores"
          :key="s.key"
          class="nav-wrap__item"
          :style="groupNameStyle"
          @click="scrollGoodGroup(s)"
        >{{ s.__groupName }}</div>
      </div>
    </div>

    <bg-style
      v-for="s in model.stores"
      :id="`${model.id}-group-${s.key}`"
      :key="s.key"
      v-imloading="pushing"
      :bg="{ ...s, ...{ backgroundBlur: model.backgroundBlur } }"
      :class="{
        'store-item': true,
        empty: !(s.__goods && s.__goods.length)
      }"
    >
      <div v-if="showGroupName && s.__groupName && !editing" class="group-name">
        <span :style="groupNameStyle">{{ s.__groupName }}</span>
      </div>
      <div v-if="showGroupName && editing" class="group-name">
        <span :style="groupNameStyle">
          {{
          s.__groupName || $t('menu.shopSection', { number: s.key })
          }}
        </span>
      </div>
      <!-- 商店板块 -->
      <div
        ref="store"
        :class="[
          'flex',
          'store-goods-wrap',
          isLeftLayout ? 'justify-start' : 'justify-center'
        ]"
      >
        <div
          v-if="s.select[0].value && s.select[1].value"
          :class="[
            'flex',
            'flex-wrap',
            'store-goods',
            leftLayoutNumClassName,
            isBackgroundGoods ? 'background-goods' : 'normal-goods',
            isLeftLayout ? 'justify-start' : 'justify-center',
          ]"
        >
          <OneGood
            v-for="(good, index) in s.__goods"
            :key="good.code"
            :is-last-one="index === s.__goods.length - 1"
            :good="good"
            :is-mobile="isMobile"
            :editing="editing"
            :show-car="showCar"
            :store-lang="storeLang"
            :info-menu-visible="infoMenuVisible"
            :model="model"
            @buy="buy"
          />
        </div>
        <!-- 商品模板（未选取商品时展示） -->
        <div
          v-if="!s.select[1].value && editing"
          :class="[
            'flex',
            'flex-wrap',
            'store-goods',
            'skeleton',
            leftLayoutNumClassName,
            isBackgroundGoods ? 'background-goods' : 'normal-goods',
            isLeftLayout ? 'justify-start' : 'justify-center',
          ]"
        >
          <OneGood
            v-for="good in 4"
            :key="good"
            is-tmp
            :good="{names: good, activities: [], id: good}"
            :editing="editing"
            :show-car="showCar"
            :store-lang="storeLang"
            :info-menu-visible="infoMenuVisible"
            :model="model"
          />
        </div>
      </div>
    </bg-style>
    <!-- 支付结果 -->
    <payresult v-model="showpayresult" :device="device" @close="showpayresult = false"></payresult>
    <!--PC端弹框 -->
    <DeskPayModal
      v-if="deskPay.visible"
      :desk-pay="deskPay"
      :order-data="orderData"
      :store-lang="storeLang"
      :lang-id-data="langIdData"
      @close="closePayModal"
    />
  </widget-content>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import isNil from 'lodash.isnil'
import cloneDeep from 'lodash.clonedeep'
import OneGood from './oneGood/index.vue'
import tools from '~/utils'
import WidgetContent from '~/site/components/widgetContent'
import payresult from '~/components/pay/payresult.vue'
import DeskPayModal from '~/components/pay/deskPayModal.vue'
import Bus, { emitWinBus } from '~/site/model/bus'
import richText from '~/components/richText'
import { isMobile } from '~/utils/types'
import { GET_SITE_ID, default_site_lang, PAY_URL, isBuildWebSite, isPre } from '~/config'
import InfoMenuIcon from '~/assets/svg/icon/info-menu.svg?inline'
import { DeviceEnum } from '~/enums/deviceEnum';
import { scrollTo } from '~/utils/dom'

let isPreviewMode = !isBuildWebSite
let isSandBox = !isBuildWebSite // 是否是建处出来的网站，检出来的网站用正式模式其他的用沙盒模式
const SiteID = GET_SITE_ID()
// eslint-disable-next-line no-console
if (isBuildWebSite && isPre && SiteID.startsWith('56') && SiteID.length === 2) {
  // myCard验收商城
  isSandBox = isPreviewMode = true
}
export default {
  name: 'StoreWidgetEdit',
  components: {
    richText,
    payresult,
    WidgetContent,
    OneGood,
    InfoMenuIcon,
    DeskPayModal
  },

  props: {
    model: {
      type: Object,
      default() {
        return {}
      }
    },
    editing: {
      type: Boolean,
      default: false,
    },
    device: {
      type: String,
      default: 'desktop',
    },
  },
  data() {
    return {
      that: this,
      lang: tools.getLang(this),
      storeLang: default_site_lang,
      showpayresult: false,
      currencies: [],
      currencieMap: null,
      pushing: false, //  加购中
      return_url: '', // 支付返回地址
      // originCdn: 'https://koppay-1252325870.cos.accelerate.myqcloud.com/payStation/dist/bundle.js', // 旧地址
      // originCdn: 'https://kopglobal.com/cdn/sdk/koppay-1.0.28.js', // 新地址 谷歌云
      infoMenuVisible: true,
      // PC端支付弹框
      deskPay: {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      },
      orderData: {}
    }
  },
  computed: {
    ...mapState({
      realDevice: (state) => state.device.device,
      userInfo: (state) => state.user.siteUserInfo,
      projectInfo: (state) => state.project.info.project,
      projectId: (state) => state.project.projectId,
      isUpdateUuid: state => state.user.isUpdateUuid,
      langIdData: state => state.locale.langIdData,
      shopGood: state => state.site.shopGood
    }),
    showStoreTitle() {
      const res = this.model.layout.find((m) => {
        return m.key === 'storeTitle'
      })
      return res && res.value
    },
    titleText() {
      const res = this.model.layout.find((m) => {
        return m.key === 'storeTitle'
      })
      return res && res.titleText
    },
    showGroupName() {
      const res = this.model.layout.find((m) => {
        return m.key === 'group'
      })
      return res && res.value
    },
    showCar() {
      const res = this.model.layout.find((m) => {
        return m.key === 'car'
      })
      return res && res.value
    },
    groupNameStyle() {
      if (this.model?.groupNameColorShow) {
        return {
          color: this.model.groupNameColor,
        }
      }
      return {}
    },

    isMobile() {
      const device = this.editing
        ? this.device
        : this.realDevice
      return device === DeviceEnum.MOBILE
    },

    /**
     * 非编辑状态下，没有商品，则不展示布局按钮
     */
    isShowLayoutBtn() {
      const hasGoods = this.model?.stores.some(store => !!store?.__goods?.length)
      const isNormalGoodLayout = this.model.goodsLayoutMethod === 'normal'
      return this.isMobile && hasGoods && isNormalGoodLayout
    },

    /**
     * 商品组小于 2，则不展示导航
     */
    isShowStoreNav() {
      return this.model?.stores?.length >= 2
    },

    isLeftLayout() {
      return !this.isMobile && this.model.layoutMethod === 'num'
    },

    leftLayoutNumClassName() {
      const classNameMap = {
        1: 'row-one',
        2: 'row-two',
        3: 'row-three'
      };
      if (this.isLeftLayout) {
        return classNameMap[this.model.layoutMethodNum]
      }
      return '';
    },

    /**
     * 商品是否拥有背景布局
     */
    isBackgroundGoods() {
      return this.model.goodsLayoutMethod === 'background'
    },
  },
  async created() {
    // 获取商店语言环境 ，设计时默认en, 运行时取路由参数
    // eslint-disable-next-line
    this.storeLang = tools.getStoreLang(this)

    // 获取ip信息
    await this.getIpInfo()

    // 获取货币列
    await this.getCurrencies()

    // 获取商品信息
    this.pushing = (!this.isUpdateUuid && !this.editing)
    this.getAllGoods()
    await this.initShopCart()

  },
  mounted() {
    Bus.$on('updateGood', (sitem, gitem) => {
      const params = {
        group_id: gitem.value,
        pageSize: 100,
        pageNo: 1,
      }
      this.getGoods(params, sitem, this.editing)
    })

    // Bus.$on('sortChange', (key) => {
    //   const stroe = this.model.stores.find((item) => {
    //     return item.key === key
    //   })
    //   const sotrInfo = stroe.sortKey.split('-')
    //   const skey = sotrInfo[0]
    //   const type = sotrInfo[1]
    //   stroe.__goods = this.sortByKey(stroe.__goods, skey, type)
    // })

    Bus.$on('reloadGood', (type) => {
      if (type === 'login-out') this.$store.commit('site/SET_SHOP_GOOD', {})
      this.getIpInfo()
        .then(() => {
          this.getAllGoods()
          this.getShopCart(this)
        })
    })

    // 检查是否是支付页面跳转回来的
    this.checkStatus()
    this.return_url = `${window.location.protocol}//${window.location.hostname === 'localhost' ? 'sitebuilder.im30.net' : window.location.host}${window.location.pathname}`
  },
  destroyed() {
    Bus.$off('updateGood')
    // Bus.$off('sortChange')
    Bus.$off('reloadGood')
  },
  methods: {
    ...mapMutations({
      'SET_STORE_GOODS': 'site/SET_STORE_GOODS'
    }),
    ...mapActions({
      'getShopCart': 'goods/getShopCart'
    }),
    async initShopCart() {
      if (this.editing || !this.$store.state.user.siteLoginUserInfo) return
      await this.getShopCart(this)
    },
    async getIpInfo() {
      // const StoreIpInfo = tools.getLangByip(this)
      // if (StoreIpInfo && StoreIpInfo.ip) {
      //   return
      // }
      // 部分设备，无痕，隐私模式下读取localStorage报错
      let ipInfo = {}
      try {
        ipInfo = tools.getxfip(localStorage)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
      const options = {
        headers: ipInfo,
      }
      const [err, result] = await this.$utils.to(
        this.$api.common.getIpInfo({}, options)
      )
      if (!err) {
        this.$store.commit('user/SET_IPINFO', result)
      } else {
        // eslint-disable-next-line no-console
        console.log('获取ip报错：', err)
      }
      return Promise.resolve()
    },
    getAllGoods() {
      this.model.stores.forEach((s) => {
        const params = {
          group_id: s.select[1].value,
          pageSize: 100,
          pageNo: 1,
          project_id: this.projectId
        }
        this.getGoods(params, s, this.editing)
      })
    },
    emitWinBusInit() {
      this.$nextTick(() => {
        emitWinBus('goods-init', this.$refs.store)
      })
    },
    // 获取商品信息
    async getGoods(params, s, editing) {
      if (!params.group_id) {
        this.pushing = false
        return
      }
      const [err, result] = await this.$utils.to(this.$api.good.getVirtualItems(params, { editing }))
      this.pushing = false
      if (!err && result) {
        if (result?.discount_info?.discount_level) {
          this.$store.commit('goods/SET_DISCOUNT_INFO', cloneDeep(result.discount_info))
        }
        const groupEnabled = result.group && !!result.group.enabled
        const data = groupEnabled ? result.data.map(item => {
          const newItem = {...item}
          newItem.activity_rule = JSON.parse(newItem.activity_rule || '{}')
          return newItem
        }) : []

        // 筛选状态为开启的商品
        const tempGoods = data ? data.filter((item) => item.enabled) : []

        // 根据sortKey排序
        // const sotrInfo = s.sortKey.split('-')
        // const skey = sotrInfo[0]
        // const type = sotrInfo[1]

        // 建站器不检测
        if (!editing) {
          // 查看未登录时购买的商品是否是限购的商品
          const currentGood = tempGoods.find(i => i.id === this.shopGood.id)
          const isCanBuy = !!currentGood && !(currentGood.purchase_limits > 0 && currentGood.left === 0)
          if (isCanBuy) this.createOrder(currentGood)
        } else {
          this.$store.commit('site/SET_SHOP_GOOD', {})
        }

        // tempGoods = this.sortByKey(tempGoods, skey, type)
        this.$set(s, '__goods', tempGoods)

        this.SET_STORE_GOODS(cloneDeep(this.model.stores))

        const { lang_id } = result?.group?.names
        const groupName = lang_id
          ? this.langIdData[lang_id] || lang_id
          : result?.group?.names[this.storeLang]

        this.$set(s, '__groupName', groupName)

        this.emitWinBusInit()
      } else {
        this.$imessage({
          content: err?.message,
          type: 'warning',
        })
      }
    },
    // 购买
    buy(good) {
      if (this.editing) return
      if (isNil(this.userInfo?.id)) {
        this.$store.commit('site/SET_SHOP_GOOD', good)
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        this.$store.commit('user/SET_SITE_BIND_USER', true)
        return
      }
      this.createOrder(good)
    },
    createOrder(good) {
      if (this.createing) return
      this.createing = true
      const StoreIpInfo = tools.getLangByip(this)
      const { currency } = StoreIpInfo
      // this.$logger.capturePayMessage('创建订单中')
      const {
        project_id,
        merchant_id
      } = this.userInfo
      let ipInfo = {}
      try {
        ipInfo = tools.getxfip(localStorage)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
      const priceInfo = tools.getPriceInfo(good.prices_setting, StoreIpInfo, this);
      const price = +priceInfo.price;
      const device = isMobile() ? 'mobile' : 'desktop'
      const lang = tools.getNeedParamsFormRouteParams(this.$route.params)?.lang || 'en'
      const sandbox = isSandBox ? 1 : 0  //  true : false
      const params = {
        project_id,
        merchant_id,
        currency,
        amount: price,
        from_preview_mode: isPreviewMode,
        items: [
          {
            item_id: good.id,
            num: 1,
            amount: price,
            price,
            currency,
            virtual_currency: currency,
            virtual_currency_amount: price,
          },
        ],
        settings: {
          device,
          language: lang,
          show_order: false,
          return_url: this.return_url,
        },
      }
      this.orderData = cloneDeep(params)
      this.$api.order.createOrder(params, ipInfo).then((res) => {
          // this.$logger.captureMessage('创建订单成功' + JSON.stringify(res))
          if (res.token) {
            this.openPayModal(res, sandbox, device)
          }
      }).catch((err) => {
          // this.$logger.captureMessage('创建订单失败' + err)
          this.$imessage({
            content: typeof err === 'string' ? err : err?.message,
            type: 'error',
          })
        })
        .finally(() => {
          this.createing = false
        })
    },
    // open pay modal PC
    openPayModal(res, sandbox, device) {
      const { token, code } = res
      let payUrl = ''
      const env = process.env.RUN_ENV
      const isGoPay = token.slice(0, 2) === 'v2'
      if (isGoPay) {
        payUrl = `${PAY_URL[env]}/new-kop/payelement?access_token=${token}&sandbox=${+sandbox}`
      } else {
        payUrl = `${PAY_URL[env]}/pay/payelement?access_token=${token}&sandbox=${+sandbox}`
      }
      if (device === 'mobile') {
        window.open(payUrl, '_self')
      } else {
        this.deskPay = {
          visible: true,
          isGoPay,
          payUrl,
          orderCode: code
        }
      }
    },
    // close pay modal PC
    closePayModal() {
      // 清空待购买商品
      this.$store.commit('site/SET_SHOP_GOOD', {})
      // 购买结束刷新商品
      this.getAllGoods()
      Bus.$emit('reloadActivity')
      // 关闭弹框
      this.deskPay = {
        visible: false,
        isGoPay: false,
        payUrl: '',
        orderCode: ''
      }
    },
    // SDK打开支付
    // openPay(token, sandbox) {
    //   if (!token) return
    //   const that = this
    //   // const env = !isProduction ? 'pre' : process.env.RUN_ENV // pre.development.release
    //   const PayStation = new window.KopPay({
    //     accessToken: token,
    //     env: process.env.RUN_ENV,
    //     sandbox,
    //     debug: false,
    //     contentPadding: 30,
    //     lightbox: {
    //       width: 800,
    //       height: 600,
    //       spinner: 'puff',
    //       closeable: true,
    //       closeOnClickMask: false,
    //     },
    //     on: {
    //       load(e) {
    //         // eslint-disable-next-line no-console
    //         console.log(`SDK加载完成`, e)
    //       },
    //       beforeClose() {
    //         // 购买结束刷新商品
    //         that.getAllGoods()
    //         return new Promise((resolve) => {
    //           resolve(true)
    //         })
    //       },
    //     },
    //   })
    //   PayStation.open()
    // },
    // 检查是否是从第三方支付返回 带payment_id（订单id）表示需要展示支付结果
    checkStatus() {
      const paymentId = '' // tools.getQueryString('access_token');

      if (paymentId) {
        if (!tools.getQueryString('isHide')) {

          if (window.showpayresult) return
          this.showpayresult = Boolean(paymentId)
          window.showpayresult = true
          if (this.showpayresult) {
            // this.$logger.capturePayMessage('显示支付结果弹窗')
          }
        }
      }
    },

    // 获取货币列表
    async getCurrencies() {
      const res = await this.$api.common.getCurrencies()
      this.currencies = res.data
      this.currencieMap = {}
      this.currencies.forEach((c) => {
        this.currencieMap[c.id] = c
      })
      this.$store.commit('project/SET_CURRENCYINFO', {
        currency: this.currencies,
        currencieMap: this.currencieMap,
      })
    },

    /**
     * @description 根据key进行排序
     * @param {*} data 待排序数组，[{},{},{}]
     * @param {*} key 待排序关键字，string
     * @param {*} type 排序规则 升序asc 降序desc
     */
    // sortByKey(data = [], key = 'price', type = 'asc') {
    //   // 获取货币信息
    //   const info = tools.getLangByip(this)
    //   if (key === 'price') {
    //     data.sort((a, b) => {
    //       const price1 =
    //         (a.prices_setting[info.country] && a.prices_setting[info.country][info.currency] && a.prices_setting[info.country][info.currency].default_value) ||
    //         0
    //       const price2 =
    //         (b.prices_setting[info.country] && b.prices_setting[info.country][info.currency] && b.prices_setting[info.country][info.currency].default_value) ||
    //         0
    //       if (type === 'asc') {
    //         // 升序
    //         return price1 - price2
    //       } else {
    //         // 降序
    //         return price2 - price1
    //       }
    //     })
    //   }

    //   if (key === 'id') {
    //     data.sort((a, b) => {
    //       const date1 = a.id || 0
    //       const date2 = b.id || 0
    //       if (type === 'asc') {
    //         // 升序
    //         return date1 - date2
    //       } else {
    //         // 降序
    //         return date2 - date1
    //       }
    //     })
    //   }
    //   return data
    // },

    /**
     * 商品加入购物车
     */
    pushCar(good) {
      if (this.editing) return
      if (!this.userInfo) {
        this.$store.commit('user/SET_LOGINSHOW', !this.editing)
        return
      }
      const params = {
        item_id: good.id,
        num: 1,
        project_id: this.userInfo.project_id,
        user_id: this.userInfo.uid,
      }
      this.pushing = true
      this.$api.car
        .pushCart(params, { editing: this.editing })
        .then((result) => {
          this.pushing = false
        })
    },

    // 商店板块自定义背景图片
    storeBackImgStyle(item) {
      if (item.customBackImg && item.backgroundImage) {
        return {
          backgroundImage: item.backgroundImage
            ? `url(${item.backgroundImage})`
            : 'none',
        }
      } else {
        return {}
      }
    },

    /**
     * 处理商品导航
     *
     * @param {*} group
     */
    scrollGoodGroup(group) {
      scrollTo(`${this.model.id}-group-${group.key}`, 'site-body', 50)
    }
  },
}
</script>

<style lang="less" scoped>
.background-goods {
  // scale: 1.2;
}

.normal-goods {
  column-gap: 32px;
}

.store-widget-wrap {
  position: relative;
  background-size: 100%;
  padding: 80px 0;
  overflow: hidden;

  .nav {
    &-container {
      @apply relative overflow-x-auto pt-3 box-border;
      width: calc(100% - 40px);
      left: 20px;
      right: 20px;
      color: var(--text-color);
    }

    &-wrap {
      @apply flex justify-start flex-nowrap mr-3;

      &__item {
        @apply mr-3 cursor-pointer;
      }
    }
  }

  .back-color-wrap {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .store-title-rich-wrap {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: var(--title-background-image);
  }

  .store-item {
    padding: 26px 0 16px 0;
    position: relative;
    background-size: 100%;
    min-height: 300px;

    .back-color-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .group-name {
      text-align: center;
      position: relative;
      margin-top: 24px;
      margin-bottom: 26px;

      span {
        padding: 8px 16px;
        font-size: 24px;
        // color: rgba(255, 255, 255, 0.6);
        color: var(--text-color-alpha-06);
        // background: rgba(92, 111, 254, 0.1);
        border-radius: 4px;
      }
    }

    .store-goods-wrap {
      width: 100%;

      .store-goods {
        // width: 1224px;
        // overflow-y: auto;
        position: relative;

        &.row {
          &-one {
            padding-right: calc(100% - 288px); // 288px * 1
          }

          &-two {
            padding-right: calc(100% - 588px);
          }

          &-three {
            padding-right: calc(100% - 898px);
          }
        }
      }
    }

    &.empty {
      min-height: auto;
      padding: 0px !important;
    }
  }

  .x-auto {
    overflow-x: auto;
    overflow-y: hidden;
  }

  // 兼容线上环境样式丢失
  .truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .flex-wrap {
    flex-wrap: wrap;
  }
}

// 750px 样式
.site-device-750 {
  .store-widget-wrap {
    min-width: auto;
    padding: 24px 0;

    .store-title-rich-wrap {
      .title-rich {
        .ql-toolbar.ql-snow {
          top: 0;
        }
      }
    }

    .store-item {
      padding: 12px 0 16px 0;

      &:last-child {
        padding-bottom: 0px;
      }

      .group-name {
        margin: 8px 0 16px;

        span {
          @apply text-16;
        }
      }

      .store-goods-wrap {
        .store-goods {
          @apply w-full overflow-hidden mb-5;
          column-gap: 0px;
          padding: 0 8px;

          &:last-child {
            @apply mb-0;
          }
        }
      }
    }
  }
}

.store-menu__icon {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 20px;
  height: 20px;
  font-size: 20px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
  }
}
</style>
<style lang="less">
.store-widget-wrap {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert !important;
  }
}
</style>
